<template>
  <div class="tabBarPageBox">
    <el-tabs v-model="activeNameTemp" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane v-for="(item,index) in tabsList" :key="index" :label="item.label" :name="item.name" />
    </el-tabs>
  </div>
</template>

<script>
export default {
  props: {
    tabsList: {
      default: () => [],
      type: Array
    },
    activeName: {
      default: '',
      type: [String, Number]
    }
  },

  data() {
    return {
      activeNameTemp: this.activeName
    }
  },
  watch: {
    activeName: {
      handler(val) {
        if (!val) {
          this.activeNameTemp = '0'
          return
        }
        this.activeNameTemp = val
      }
    },
    immediate: true
  },
  methods: {
    handleClick() {
      this.$emit('getTabName', this.activeNameTemp)
    }
  }
}
</script>

<style lang="scss" scoped>
.tabBarPageBox {
  .el-tabs{
    .el-tabs__item{
      height: 48px;
      line-height: 48px;
      margin-right: 0;
      color: #000;
      font-size: 14px;
    }
    .is-active{
      color:#007BE5;
    }
    .el-tabs__nav-wrap::after{
      height: 1px;
      background-color: #E7E7E7;
    }
    .el-tabs__nav-scroll{
      padding-left: 24px;
    }
    .el-tabs__active-bar{
      background-color:#007BE5;
      display: inline-block;
    }
  }
}
</style>
